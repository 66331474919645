export function ErrorPage({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center bg-red-500">
      <h1 className="font-bold text-4xl">Something went wrong</h1>
      <div className="text-xl m-3">{error.message}</div>
      {/*<button onClick={resetErrorBoundary}>Try again</button>*/}
    </div>
  );
}
