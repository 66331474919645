/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Feature } from './Feature';
import {
    FeatureFromJSON,
    FeatureFromJSONTyped,
    FeatureToJSON,
} from './Feature';
import type { UploadItemIn } from './UploadItemIn';
import {
    UploadItemInFromJSON,
    UploadItemInFromJSONTyped,
    UploadItemInToJSON,
} from './UploadItemIn';

/**
 * 
 * @export
 * @interface UploadInfo
 */
export interface UploadInfo {
    /**
     * 
     * @type {Array<UploadItemIn>}
     * @memberof UploadInfo
     */
    items: Array<UploadItemIn>;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof UploadInfo
     */
    features: Array<Feature>;
}

/**
 * Check if a given object implements the UploadInfo interface.
 */
export function instanceOfUploadInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "features" in value;

    return isInstance;
}

export function UploadInfoFromJSON(json: any): UploadInfo {
    return UploadInfoFromJSONTyped(json, false);
}

export function UploadInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(UploadItemInFromJSON)),
        'features': ((json['features'] as Array<any>).map(FeatureFromJSON)),
    };
}

export function UploadInfoToJSON(value?: UploadInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(UploadItemInToJSON)),
        'features': ((value.features as Array<any>).map(FeatureToJSON)),
    };
}

