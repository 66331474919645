/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MessageIn,
  MessageOut,
  PagableMessages,
} from '../models/index';
import {
    MessageInFromJSON,
    MessageInToJSON,
    MessageOutFromJSON,
    MessageOutToJSON,
    PagableMessagesFromJSON,
    PagableMessagesToJSON,
} from '../models/index';

export interface GetMessagesRequest {
    size?: number;
    cursor?: string;
}

export interface SendMessageRequest {
    messageIn: MessageIn;
}

/**
 * 
 */
export class QaApi extends runtime.BaseAPI {

    /**
     * messages
     */
    async getMessagesRaw(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PagableMessages>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/qa/messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagableMessagesFromJSON(jsonValue));
    }

    /**
     * messages
     */
    async getMessages(requestParameters: GetMessagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PagableMessages> {
        const response = await this.getMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async qaMessagesOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/qa/messages`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async qaMessagesOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.qaMessagesOptionsRaw(initOverrides);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async qaMessagesSendOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/qa/messages/send`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async qaMessagesSendOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.qaMessagesSendOptionsRaw(initOverrides);
    }

    /**
     * send message
     */
    async sendMessageRaw(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageOut>> {
        if (requestParameters.messageIn === null || requestParameters.messageIn === undefined) {
            throw new runtime.RequiredError('messageIn','Required parameter requestParameters.messageIn was null or undefined when calling sendMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/qa/messages/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageInToJSON(requestParameters.messageIn),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageOutFromJSON(jsonValue));
    }

    /**
     * send message
     */
    async sendMessage(requestParameters: SendMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageOut> {
        const response = await this.sendMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageAddFeedbackOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/storage/add_feedback`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageAddFeedbackOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storageAddFeedbackOptionsRaw(initOverrides);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageDeleteAccountOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/storage/delete_account`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageDeleteAccountOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storageDeleteAccountOptionsRaw(initOverrides);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageDeleteVideosOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/storage/delete_videos`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageDeleteVideosOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storageDeleteVideosOptionsRaw(initOverrides);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageUploadUrlsOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/storage/upload_urls`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageUploadUrlsOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storageUploadUrlsOptionsRaw(initOverrides);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageValidateSignupOptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/storage/validate_signup`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Enable CORS by returning correct headers 
     * CORS support
     */
    async storageValidateSignupOptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.storageValidateSignupOptionsRaw(initOverrides);
    }

}
