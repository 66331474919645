import { RootState } from "./store";
import { User } from "../lib/authentication/user";
import { Authentication } from "../lib/authentication/authentication";
import { CognitoClientId, CognitoUserPoolId } from "../environment";
import { sliceWithThunks } from "./utils";
import { StorageApi, ValidateSignupRequest } from "../net";
import { Whitelist } from "../environment";

export const authentication = new Authentication(
  CognitoUserPoolId,
  CognitoClientId,
);

interface AuthState {
  accessToken?: string | null;
  user?: User;
  loggedIn?: boolean;
  loading: boolean;
  lastError?: string;
  signedUpConfirmSucceeded: boolean;
  signedUpSucceeded: boolean;
  resendSignUpSucceeded: boolean;
  forgotPasswordSucceeded: boolean;
}

const initialState: AuthState = {
  accessToken: undefined,
  user: undefined,
  loggedIn: undefined,
  loading: false,
  lastError: undefined,
  signedUpSucceeded: false,
  resendSignUpSucceeded: false,
  signedUpConfirmSucceeded: false,
  forgotPasswordSucceeded: false,
};

interface SignupValidate {
  validated: boolean,
  message: string
};

export const authSlice = sliceWithThunks({
  name: "auth",
  initialState,
  reducers: (create) => ({
    setAccessToken: create.reducer<{ accessToken: string | null | undefined }>(
      (state, action) => {
        state.accessToken = action.payload.accessToken;
        if (action.payload.accessToken === undefined)
          state.loggedIn = undefined;
        else if (action.payload.accessToken === null) state.loggedIn = false;
        else state.loggedIn = true;
      },
    ),
    setUser: create.reducer<{ user?: User }>((state, action) => {
      state.user = action.payload.user;
      if (state.user) {
        state.user.privileged = Whitelist.includes(state.user.email) || state.user.email.endsWith('@voicera.io');
      }
    }),
    login: create.asyncThunk<void, { username: string; password: string }>(
      async (action, thunkApi) => {
        var retryCount = 0;
        var sleepTime = 1000;
        while (retryCount < 3) {
          try {
            const result = await authentication.login(action.username, action.password);
            return result;
          } catch (e) {
            if (retryCount < 3) {
              await new Promise(resolve => setTimeout(resolve, sleepTime));
              retryCount += 1;
              if (retryCount == 3) {
                retryCount = 0;
                return thunkApi.rejectWithValue("Server may be busy. Please try again after sometime!");
              }
            }
          }
        }
      },
      {
        pending: (state) => {
          state.lastError = undefined;
          state.loading = true;
        },
        rejected: (state, action) => {
          state.user = undefined;
          state.accessToken = null;
          state.lastError = action.error?.message ?? "Unknown";
        },
        settled: (state) => {
          state.loading = false;
        },
      },
    ),
    logout: create.asyncThunk(
      async () => {
        authentication.logout().then();
      },
      {
        pending: (state) => {
          state.lastError = undefined;
          state.loading = true;
        },
        rejected: (state, action) => {
          state.lastError = action.error?.message ?? "Unknown";
        },
        settled: (state) => {
          state.loading = false;
          state.loggedIn = false;
          state.user = undefined;
          state.accessToken = null;
        },
      },
    ),
    forgotPassword: create.asyncThunk<void, { username: string }>(
      async (action, thunkApi) => {
        await authentication.forgotPassword(action.username);
      },
      {
        pending: (state) => {
          state.lastError = undefined;
          state.loading = true;
        },
        rejected: (state, action) => {
          state.forgotPasswordSucceeded = false;
          state.lastError = action.error?.message ?? "Unknown";
        },
        settled: (state) => {
          state.loading = false;
        },
        fulfilled: (state) => {
          state.forgotPasswordSucceeded = true;
        },
      },
    ),
    confirmForgotPassword: create.asyncThunk<
      void,
      {
        username: string;
        code: string;
        newPassword: string;
      }
    >(
      async (action, thunkApi) => {
        var retryCount = 0;
        var sleepTime = 500;
        while (retryCount < 3) {
          try {
            const result = await authentication.confirmForgotPassword(
              action.username,
              action.code,
              action.newPassword,
            ).then();
            return result;
          } catch (e) {
            if (retryCount < 3) {
              await new Promise(resolve => setTimeout(resolve, sleepTime));
              retryCount += 1;
              if (retryCount == 3) {
                retryCount = 0;
                return thunkApi.rejectWithValue("Server may be busy. Please try again after sometime!");
              }
            }
          }
        }
      },
      {
        pending: (state) => {
          state.lastError = undefined;
          state.loading = true;
        },
        rejected: (state, action) => {
          state.lastError = action.error?.message ?? "Unknown";
          state.loading = false;
        },
        settled: (state) => {
          state.loading = false;
        },
      },
    ),
    signup: create.asyncThunk<
      void,
      {
        username: string;
        password: string;
        firstName: string;
        lastName: string;
      }
    >(
      async (action, thunkApi) => {
        await authentication.signup(
          action.username,
          action.password,
          action.firstName,
          action.lastName,
        );
      },
      {
        pending: (state) => {
          state.lastError = undefined;
          state.signedUpSucceeded = false;
          state.loading = true;
        },
        rejected: (state, action) => {
          state.lastError = action.error?.message ?? "Unknown";
          state.signedUpSucceeded = false;
          state.loading = false;
        },
        fulfilled: (state) => {
          state.signedUpSucceeded = true;
          state.loading = false;
        },
      },
    ),
    validateSignup: create.asyncThunk<
    SignupValidate,
    {
      email: string
    }
    >(
      async (action, thunkApi) => {
        const res: SignupValidate = await new StorageApi().validateSignup({
          validateSignup: action
        });
        return res;
      }
    ),
    resendSignup: create.asyncThunk<
         void,
         {
           username: string;
         }
    >(
         async (action, thunkApi) =>
         {
           await authentication.resendSignup(
                action.username,
           );
         },
         {
           pending: (state) =>
           {
             state.lastError = undefined;
             state.resendSignUpSucceeded = false;
             state.loading = true;
           },
           rejected: (state, action) =>
           {
             state.lastError = action.error?.message ?? "Unknown";
             state.resendSignUpSucceeded = false;
             state.loading = false;
           },
           fulfilled: (state) =>
           {
             state.resendSignUpSucceeded = true;
             state.loading = false;
           },
         },
    ),
    confirmSignup: create.asyncThunk<
      void,
      {
        username: string;
        code: string;
      }
    >(
      async (action, thunkApi) => {
        const completed = await authentication.confirmSignup(
          action.username,
          action.code,
        );
        completed
          ? thunkApi.fulfillWithValue({ signedUp: completed })
          : thunkApi.rejectWithValue({ signedUp: completed });
      },
      {
        pending: (state) => {
          state.lastError = undefined;
          state.loading = true;
          state.signedUpConfirmSucceeded = false;
        },
        rejected: (state, action) => {
          state.lastError = action.error?.message ?? "Unknown";
          state.loading = false;
          state.signedUpConfirmSucceeded = false;
        },
        fulfilled: (state) => {
          state.loading = false;
          state.signedUpConfirmSucceeded = true;
        },
      },
    ),
    resetError: create.reducer((state, action) => {
      state.lastError = undefined;
    }),
  }),
  selectors: {
    selectLoading: (sliceState) => sliceState.loading,
    selectSignedUpSucceeded: (sliceState) => sliceState.signedUpSucceeded,
    selectResendSignUpSucceeded: (sliceState) => sliceState.resendSignUpSucceeded,
    selectSignedUpConfirmSucceeded: (sliceState) => sliceState.signedUpConfirmSucceeded,
    selectError: (sliceState) => sliceState.lastError,
    selectLoggedIn: (sliceState) => sliceState.loggedIn,
    selectResetPasswordSucceeded: (sliceState) =>
      sliceState.forgotPasswordSucceeded,
    selectAccessToken: (sliceState) => sliceState.accessToken,
    selectUser: (sliceState) => {
      return sliceState.user;
    },
  },
});

export const {
  selectLoading,
  selectSignedUpSucceeded,
     selectResendSignUpSucceeded,
  selectSignedUpConfirmSucceeded,
  selectError,
  selectLoggedIn,
  selectResetPasswordSucceeded,
  selectAccessToken,
  selectUser,
} = authSlice.getSelectors((rootState: RootState) => rootState.auth);

export const {
  setAccessToken,
  setUser,
  login,
  logout,
  forgotPassword,
  confirmForgotPassword,
  signup,
  resendSignup,
  validateSignup,
  confirmSignup,
  resetError,
} = authSlice.actions;
