/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddFeedbackRequest,
  DeleteInfo,
  UploadInfo,
  UploadItemOut,
  ValidateSignup,
  ValidateSignupOut,
} from '../models/index';
import {
    AddFeedbackRequestFromJSON,
    AddFeedbackRequestToJSON,
    DeleteInfoFromJSON,
    DeleteInfoToJSON,
    UploadInfoFromJSON,
    UploadInfoToJSON,
    UploadItemOutFromJSON,
    UploadItemOutToJSON,
    ValidateSignupFromJSON,
    ValidateSignupToJSON,
    ValidateSignupOutFromJSON,
    ValidateSignupOutToJSON,
} from '../models/index';

export interface AddFeedbackOperationRequest {
    addFeedbackRequest: AddFeedbackRequest;
}

export interface DeleteVideosRequest {
    deleteInfo: Array<DeleteInfo>;
}

export interface UploadUrlsRequest {
    uploadInfo: UploadInfo;
}

export interface ValidateSignupRequest {
    validateSignup: ValidateSignup;
}

/**
 * 
 */
export class StorageApi extends runtime.BaseAPI {

    /**
     * add feedback
     */
    async addFeedbackRaw(requestParameters: AddFeedbackOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.addFeedbackRequest === null || requestParameters.addFeedbackRequest === undefined) {
            throw new runtime.RequiredError('addFeedbackRequest','Required parameter requestParameters.addFeedbackRequest was null or undefined when calling addFeedback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/storage/add_feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFeedbackRequestToJSON(requestParameters.addFeedbackRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * add feedback
     */
    async addFeedback(requestParameters: AddFeedbackOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * delete account
     */
    async deleteAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/storage/delete_account`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete account
     */
    async deleteAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAccountRaw(initOverrides);
    }

    /**
     * delete videos
     */
    async deleteVideosRaw(requestParameters: DeleteVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deleteInfo === null || requestParameters.deleteInfo === undefined) {
            throw new runtime.RequiredError('deleteInfo','Required parameter requestParameters.deleteInfo was null or undefined when calling deleteVideos.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/storage/delete_videos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.deleteInfo.map(DeleteInfoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete videos
     */
    async deleteVideos(requestParameters: DeleteVideosRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVideosRaw(requestParameters, initOverrides);
    }

    /**
     * get upload urls
     */
    async uploadUrlsRaw(requestParameters: UploadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UploadItemOut>>> {
        if (requestParameters.uploadInfo === null || requestParameters.uploadInfo === undefined) {
            throw new runtime.RequiredError('uploadInfo','Required parameter requestParameters.uploadInfo was null or undefined when calling uploadUrls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("cognito", []);
        }

        const response = await this.request({
            path: `/storage/upload_urls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadInfoToJSON(requestParameters.uploadInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadItemOutFromJSON));
    }

    /**
     * get upload urls
     */
    async uploadUrls(requestParameters: UploadUrlsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UploadItemOut>> {
        const response = await this.uploadUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * validate signup
     */
    async validateSignupRaw(requestParameters: ValidateSignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ValidateSignupOut>> {
        if (requestParameters.validateSignup === null || requestParameters.validateSignup === undefined) {
            throw new runtime.RequiredError('validateSignup','Required parameter requestParameters.validateSignup was null or undefined when calling validateSignup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/storage/validate_signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ValidateSignupToJSON(requestParameters.validateSignup),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ValidateSignupOutFromJSON(jsonValue));
    }

    /**
     * validate signup
     */
    async validateSignup(requestParameters: ValidateSignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ValidateSignupOut> {
        const response = await this.validateSignupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
