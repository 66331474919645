import Protected from "../protected";
import Dashboard from "./dashboard";
import { ErrorRoutePage } from "../../error-route-page";
import { lazy, Suspense } from "react";
import Loading from "../../loading";

const SincerityList = lazy(() => import("./main/sincerity-list"));
const DashboardUploads = lazy(() => import("./uploads/uploads"));
const DashboardChat = lazy(() => import("./chat/chat"));
const Settings = lazy(() => import("./settings/settings"));

export const dashboardRoutes = {
  path: "/dashboard",
  element: (
    <Protected>
      <Suspense fallback={<Loading />}>
        <Dashboard />
      </Suspense>
    </Protected>
  ),
  errorElement: <ErrorRoutePage resetErrorBoundary={() => {}} />,
  children: [
    {
      index: true,
      element: <SincerityList />,
    },
    {
      path: "upload",
      element: <DashboardUploads />,
    },
    {
      path: "chat",
      element: <DashboardChat />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
  ],
};
