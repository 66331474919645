import "aws-amplify";
import {
  confirmResetPassword,
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser, resendSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from "@aws-amplify/auth";
import { Amplify } from "aws-amplify";
import { BehaviorSubject, concat } from "rxjs";
import { Hub } from "@aws-amplify/core";
import { User } from "./user";

export class Authentication {
  readonly user$ = new BehaviorSubject<User | null | undefined>(undefined);
  readonly accessToken$ = new BehaviorSubject<string | null | undefined>(
    undefined,
  );
  private readonly userPoolId: string;
  private readonly clientId: string;

  constructor(userPoolId: string, clientId: string) {
    this.userPoolId = userPoolId;
    this.clientId = clientId;

    Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          await this.getInfo();
          await this.getAccessToken();
          break;
        case "signedOut":
          this.user$.next(null);
          break;
        case "tokenRefresh":
          break;
      }
    });
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: this.userPoolId,
          userPoolClientId: this.clientId,
          // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
          signUpVerificationMethod: "code", // 'code' | 'link'
        },
      },
    });
    concat(this.getInfo(), this.getAccessToken());
  }

  get authorizationHeader(): { Authorization: string } {
    return { Authorization: this.accessToken$.value || "" };
  }

  async login(username: string, password: string) {
    try {
      const signInResult = await signIn({ username, password });
      if (signInResult.isSignedIn) {
      } else {
        this.user$.next(null);
      }
    } catch (e) {
      throw e;
    }
  }

  async logout() {
    await signOut({ global: true });
    this.accessToken$.next(null);
  }

  async signup(
    username: string,
    password: string,
    firstName: string,
    lastName: string,
  ): Promise<void> {
    const result = await signUp({
      username,
      password,
      options: { autoSignIn: true, userAttributes: { email: username, given_name: firstName, family_name: lastName } },
    });
  }

  async resendSignup (
       username: string
  ): Promise<void>
  {
    const result = await resendSignUpCode({
      username,
    });
  }

  async confirmSignup(username: string, code: string) {
    const result = await confirmSignUp({
      username,
      confirmationCode: code,
    });
    return result.isSignUpComplete;
  }

  async forgotPassword(email: string) {
    const result = await resetPassword({ username: email });
  }

  async confirmForgotPassword(
    username: string,
    code: string,
    newPassword: string,
  ) {
    const result = await confirmResetPassword({
      username,
      confirmationCode: code,
      newPassword,
    });
  }

  private async getAccessToken() {
    try {
      const result = await fetchAuthSession({ forceRefresh: true });
      const accessToken = result.tokens?.accessToken;
      if (!accessToken) this.accessToken$.next(null);
      else this.accessToken$.next(accessToken.toString());
    } catch (e) {
      this.accessToken$.next(null);
    }
  }

  private async getInfo() {
    try {
      const user = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      this.user$.next({
        id: user.username,
        firstName: attributes.given_name? attributes.given_name!: attributes.name!,
        lastName: attributes.given_name? attributes.family_name!: null,
        email: attributes.email!,
      } as User);
    } catch (e) {
      this.user$.next(null);
    }
  }
}
