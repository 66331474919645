import { Outlet } from "react-router-dom";
import Footer from "./footer";
import { useAppSelector } from "../redux/hooks";
import { selectLoggedIn } from "../redux/authSlice";
import Loading from "../loading";
import { IconButton, useTheme } from "@mui/material";
import { createContext, useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Domain } from "../environment";

const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export default function Root() {
  const loggedIn = useAppSelector(selectLoggedIn);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const themeItem = () => {
    return (
      <div className="self-end mr-2">
        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </div>
    );
  };

  function content() {
    if (loggedIn === undefined) {
      return <Loading />;
    } else {
      return (
        <div id="content" className="w-screen h-screen flex flex-col">
          {/*{themeItem()}*/}
          <div className="flex-grow overflow-scroll">
            <Outlet></Outlet>
          </div>
          <Footer title="Voicera" website={Domain}></Footer>
        </div>
      );
    }
  }

  return <div>{content()}</div>;
}
