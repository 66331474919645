import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  
  export default function PrivacyPolicyDialog({
    onClose,
    open
  }: {
    onClose: () => void;
    open: boolean;
  }) {
  
    const handleClose = () => {
      onClose();
    };
  
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="privacy-policy-title"
        aria-describedby="privacy-policy-description"
        maxWidth={false}
        PaperProps={{
          style: {
            width: '90%',
            height: '90%',
            maxWidth: 'none',
            maxHeight: 'none',
          }
        }}
      >
        <DialogContent>
          <iframe
              src="https://voicera.io/privacy-policy/" 
              style={{ width: '100%', height: '100%', border: 'none' }}
              title="Privacy Policy"
            ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button> 
        </DialogActions>
      </Dialog>
    );
  }
  