import { useRouteError } from "react-router-dom";

export function ErrorRoutePage({
  resetErrorBoundary,
}: {
  resetErrorBoundary: () => void;
}) {
  const error: Error | Record<string, any> | unknown = useRouteError();

  function message() {
    if (error instanceof Error) {
      return error.message;
    } else if ("error" in (error as Record<string, any>)) {
      const innerError = (error as Record<string, any>)["error"] as Error;
      return innerError.message;
    } else {
      return JSON.stringify(error);
    }
  }

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <h1 className="font-bold text-4xl">Something went wrong</h1>
      <div className="text-xl m-3">{message()}</div>
      {/*<button onClick={resetErrorBoundary}>Try again</button>*/}
    </div>
  );
}
