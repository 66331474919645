import { Button } from "@mui/material";
import React from "react";

export default function FormButton({
  handleClick,
  isDisabled,
  children,
  isLoading = false,
  group = undefined,
  loadingTitle = "Loading...",
}: {
  handleClick: () => void;
  isDisabled: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
  group?: string;
  loadingTitle?: string;
}) {
  function showLoading() {
    if (!isLoading) return;

    return (
      <div className="w-full flex flex-column justify-between">
        <div className="h-[24px] w-[24px] border-white loading animate-spin rounded-full border-2 border-t-primary-accent" />
        <div className="grow">{loadingTitle}</div>
      </div>
    );
  }

  function showBody() {
    return children;
  }

  return (
    <Button
      variant="contained"
      sx={{ textTransform: "none" }}
      className="flex w-full justify-center"
      onClick={handleClick}
      disabled={isDisabled}
    >
      {showLoading() || showBody()}
    </Button>
  );
}
