/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UploadItemKind = {
    TextDocument: 'textDocument',
    Video: 'video',
    Audio: 'audio'
} as const;
export type UploadItemKind = typeof UploadItemKind[keyof typeof UploadItemKind];


export function UploadItemKindFromJSON(json: any): UploadItemKind {
    return UploadItemKindFromJSONTyped(json, false);
}

export function UploadItemKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadItemKind {
    return json as UploadItemKind;
}

export function UploadItemKindToJSON(value?: UploadItemKind | null): any {
    return value as any;
}

