/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SincerityStatus = {
    InProgress: 'in_progress',
    Failed: 'failed',
    Done: 'done',
    Deleted: 'deleted',
    Pending: 'pending'
} as const;
export type SincerityStatus = typeof SincerityStatus[keyof typeof SincerityStatus];


export function SincerityStatusFromJSON(json: any): SincerityStatus {
    return SincerityStatusFromJSONTyped(json, false);
}

export function SincerityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SincerityStatus {
    return json as SincerityStatus;
}

export function SincerityStatusToJSON(value?: SincerityStatus | null): any {
    return value as any;
}

