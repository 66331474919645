import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import TermsAndConditionsDialog from "./auth/terms-and-conditions";
import PrivacyPolicyDialog from "./auth/privacy-policy";

export default function Footer({
  title,
  website,
}: {
  title: string;
  website: string;
}) {
  const [tcOpen, setTcOpen] = useState<boolean>(false);
  const [ppOpen, setPpOpen] = useState<boolean>(false);

  function acceptTOU() {
    setTcOpen(false);
  }

  function cancelTOU() {
    setTcOpen(false);
  }

  function closePrivacyPolicy() {
    setPpOpen(false); 
  }

  return (
    <footer className="shadow sm:flex sm:items-center sm:justify-between p-2 antialiased border-t bg-background dark:bg-background-dark">
      <p className="mb-4 text-[10px] text-center sm:mb-0">
         ©2024{" "}
        <RouterLink
          to={`https://${website}`}
          className="hover:underline"
          target="_blank"
        >
          {title}
        </RouterLink>
        {" "} AI, Inc.
        <Link className="pl-2 cursor-pointer" onClick={() => setTcOpen(true)}>Terms of Use</Link>
        <Link className="pl-2 cursor-pointer" onClick={() => setPpOpen(true)}>Privacy Policy</Link>
      </p>
      <TermsAndConditionsDialog onAccept={acceptTOU} onCancel={cancelTOU} onClose={() => setTcOpen(false)} open={tcOpen} isSignUpPage={false}/>
      <PrivacyPolicyDialog onClose={closePrivacyPolicy} open={ppOpen}/>
    </footer>
  );
}
