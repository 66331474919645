import { createBrowserRouter } from "react-router-dom";
import Root from "./root";
import { ErrorRoutePage } from "../error-route-page";
import { initialize } from "../lib/initialize";
import { authRoutes } from "./auth/routes";
import { dashboardRoutes } from "./dashboard/routes";
import { AuthRedirectProvider } from "../lib/authentication/auth-redirect";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthRedirectProvider nonAuthUrl="/auth/login" authUrl="/dashboard">
        <Root />
      </AuthRedirectProvider>
    ),
    loader: initialize,
    errorElement: <ErrorRoutePage resetErrorBoundary={() => {}} />,
    children: [authRoutes, dashboardRoutes],
  },
]);
