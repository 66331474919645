import Auth from "./auth";
import { ErrorRoutePage } from "../../error-route-page";
import { lazy, Suspense } from "react";
import Loading from "../../loading";
import ResendForgotPassword from "./resend-forgot-password";
import ResendSignup from "./resend-signup";

const Login = lazy(() => import("./login"));
const ForgotPassword = lazy(() => import("./forgot-password"));
const ConfirmForgotPassword = lazy(() => import("./confirm-forgot-password"));
const Signup = lazy(() => import("./signup"));
const ConfirmSignup = lazy(() => import("./confirm-signup"));

export const authRoutes = {
  path: "/auth",
  element: (
    <Suspense fallback={<Loading />}>
      <Auth />
    </Suspense>
  ),
  errorElement: <ErrorRoutePage resetErrorBoundary={() => {}} />,
  children: [
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "resend-forgot-password",
      element: <ResendForgotPassword/>,
    },
    {
      path: "confirm-forgot-password",
      element: <ConfirmForgotPassword />,
    },
    {
      path: "signup",
      element: <Signup />,
    },
    {
      path: "resend-signup",
      element: <ResendSignup/>,
    },
    {
      path: "confirm-signup",
      element: <ConfirmSignup />,
    },
  ],
};
