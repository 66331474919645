export default function Loading() {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div className="flex flex-col items-center">
        <div className="border-gray-300 h-20 w-20 animate-spin rounded-full border-2 border-t-blue-600" />
        <div className="grow">Loading...</div>
      </div>
    </div>
  );
}
