import SidebarLogoIcon from "../../assets/sidebar-logo.svg";
import React from "react";

export default function Logo() {
  return (
    <div>
      <img className="w-auto h-10" src={SidebarLogoIcon} alt="" />
      <div className="text-[10px] font-bold translate-x-[85px] -translate-y-[8px] text-sincerity-logo">
        SINCERITY
      </div>
    </div>
  );
}
