/*

  The following JS script needs to be added in the Terms of Use page in the Wordpress admin portal in order to emit on scroll events
  in this page: https://voicera.io/terms-of-use.
  1. Go to Pages > All pages > Terms of Use
  2. Under Layout Elements, scroll to the bottom of the content. Click on Code Block
  3. Under Content, add the script inside the Code Block Content. Add "script" tag under Code Wrapper Element
  4. Save changes
  
  <script>
    document.addEventListener("DOMContentLoaded", () => {
      const targetElement = document.querySelector(".sub_menu_socket");

      const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                  window.parent.postMessage({
                      type: "ELEMENT_VISIBLE",
                      id: targetElement.id
                  }, 'http://localhost:3000');

                  window.parent.postMessage({
                      type: "ELEMENT_VISIBLE",
                      id: targetElement.id
                  }, 'https://dev1.sincerity.voicera.io');

                  window.parent.postMessage({
                      type: "ELEMENT_VISIBLE",
                      id: targetElement.id
                  }, 'https://sincerity.voicera.io');
              }
          })
          }, {
              threshold: 1
          });

      if (targetElement) {
          observer.observe(targetElement);
      }
      });
    </script>

*/


import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function TermsAndConditionsDialog({
  onAccept,
  onCancel,
  onClose,
  open,
  isSignUpPage
}: {
  onAccept: () => void;
  onCancel: () => void;
  onClose: () => void;
  open: boolean;
  isSignUpPage: boolean;
}) {

  const [acceptEnabled, setAcceptEnabled] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
  };

  const handleAccept = () => {
    onAccept();
  }

  const handleCancel = () => {
    onCancel();
  }

  window.addEventListener("message", (event) => {
    if (event.data.type == "ELEMENT_VISIBLE") {
      setAcceptEnabled(true);
    }
  })


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      PaperProps={{
        style: {
          width: '90%',
          height: '90%',
          maxWidth: 'none',
          maxHeight: 'none',
        }
      }}
    >
      <DialogContent>
        <iframe
            src="https://voicera.io/terms-of-use"
            style={{ width: '100%', height: '100%', border: 'none' }}
          ></iframe>
      </DialogContent>

      {/* This code will be required later to enable accept TOU on scroll */}
      {/* <DialogActions>
        {
          isSignUpPage ? (
            <Button onClick={handleAccept} disabled={!acceptEnabled}>Accept</Button>
          ) : <></>
        }
        
        {
          isSignUpPage ? (
            <Button onClick={handleCancel}>Cancel</Button>
          ) : (<Button onClick={handleClose}>Close</Button>)
        }
        
      </DialogActions> */}

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>

    </Dialog>
  );
}
