import React, { createContext, useEffect } from "react";
import { router } from "../../routes/router";
import { useAppSelector } from "../../redux/hooks";
import { selectAccessToken, selectUser } from "../../redux/authSlice";
import { useLocation } from "react-router-dom";

export const AuthRedirect = createContext<string | null | undefined>(undefined);

export function AuthRedirectProvider({
  children,
  nonAuthUrl,
  authUrl,
}: {
  children: React.ReactNode;
  nonAuthUrl: string;
  authUrl: string;
}) {
  const accessToken = useAppSelector(selectAccessToken);
  const user = useAppSelector(selectUser);
  const location = useLocation();

  useEffect(() => {
    if (
      (location.pathname.startsWith("/auth") || location.pathname === "/") &&
      user &&
      accessToken
    )
      router.navigate(authUrl, { replace: true }).then();
    else if (location.pathname === "/")
      router.navigate(nonAuthUrl, { replace: true }).then();
  }, [location, accessToken, user, authUrl, nonAuthUrl]);

  return <>{children}</>;
}
