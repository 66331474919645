import { Navigate } from "react-router-dom";
import React from "react";
import { useAppSelector } from "../redux/hooks";
import { selectAccessToken } from "../redux/authSlice";

/*
prevent navigating to pages that require authorization
* */
export default function Protected({ children }: { children: React.ReactNode }) {
  const accessToken = useAppSelector(selectAccessToken);

  if (accessToken === undefined)
    return (
      <>
        <Navigate to="/"></Navigate>
      </>
    );
  else if (accessToken === null) return <Navigate to="/auth/login" replace />;
  else return <>{children}</>;
}
