/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddFeedbackRequest
 */
export interface AddFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof AddFeedbackRequest
     */
    srcId?: string;
    /**
     * 
     * @type {number}
     * @memberof AddFeedbackRequest
     */
    feedbackRating?: number;
    /**
     * 
     * @type {string}
     * @memberof AddFeedbackRequest
     */
    feedbackComment?: string;
    /**
     * 
     * @type {string}
     * @memberof AddFeedbackRequest
     */
    fileExtension?: string;
}

/**
 * Check if a given object implements the AddFeedbackRequest interface.
 */
export function instanceOfAddFeedbackRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddFeedbackRequestFromJSON(json: any): AddFeedbackRequest {
    return AddFeedbackRequestFromJSONTyped(json, false);
}

export function AddFeedbackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFeedbackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'srcId': !exists(json, 'srcId') ? undefined : json['srcId'],
        'feedbackRating': !exists(json, 'feedbackRating') ? undefined : json['feedbackRating'],
        'feedbackComment': !exists(json, 'feedbackComment') ? undefined : json['feedbackComment'],
        'fileExtension': !exists(json, 'fileExtension') ? undefined : json['fileExtension'],
    };
}

export function AddFeedbackRequestToJSON(value?: AddFeedbackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'srcId': value.srcId,
        'feedbackRating': value.feedbackRating,
        'feedbackComment': value.feedbackComment,
        'fileExtension': value.fileExtension,
    };
}

