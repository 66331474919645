import React, { useEffect, useState } from "react";
import FormButton from "../../lib/ui/form-button";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  forgotPassword,
  selectError,
  selectLoading,
} from "../../redux/authSlice";
import Logo from "../../lib/ui/logo";
import { useLocation } from "react-router-dom";

export default function ResendForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const dispatch = useAppDispatch();
  const lastError = useAppSelector(selectError);
  const isLoading = useAppSelector(selectLoading);
  const { state } = useLocation();

  useEffect(() => {
    setEmail(state["email"] || "");
  }, [state]);

  function onSubmit() {
    dispatch(forgotPassword({ username: email }));
  }

  function showLastError() {
    if (lastError)
      return <span className="text-red-500 text-xs">{lastError}</span>;
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="flex items-center justify-center">
          <Logo />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div>Look for your confirmation email to&nbsp;</div>
        <strong>{email}</strong>.
      </div>
      <div className="w-full flex justify-center">
        If you did not receive an email, please check your Spam folder and try resending.
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {showLastError()}
        <div>
          <FormButton
            isLoading={isLoading}
            handleClick={onSubmit}
            isDisabled={false}
          >
            Resend
          </FormButton>
        </div>
      </div>
    </div>
  );
}
