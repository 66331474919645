import { LoaderFunctionArgs } from "react-router-dom";
import { skipWhile, take } from "rxjs";
import { authentication } from "../redux/authSlice";

export function initialize(params: LoaderFunctionArgs) {
  return new Promise<boolean>((resolve) => {
    authentication.accessToken$
      .pipe(
        skipWhile((value) => value === undefined),
        take(1),
      )
      .subscribe((value) => {
        resolve(true);
      });
  });
}
