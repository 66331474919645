/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SincerityResult } from './SincerityResult';
import {
    SincerityResultFromJSON,
    SincerityResultFromJSONTyped,
    SincerityResultToJSON,
} from './SincerityResult';

/**
 * 
 * @export
 * @interface PagableSincerityResults
 */
export interface PagableSincerityResults {
    /**
     * 
     * @type {Array<SincerityResult>}
     * @memberof PagableSincerityResults
     */
    items: Array<SincerityResult>;
    /**
     * 
     * @type {Array<SincerityResult>}
     * @memberof PagableSincerityResults
     */
    demoItems: Array<SincerityResult>;
    /**
     * 
     * @type {number}
     * @memberof PagableSincerityResults
     */
    numUploadsThisMonth: number;
    /**
     * 
     * @type {string}
     * @memberof PagableSincerityResults
     */
    cursor: string | null;
}

/**
 * Check if a given object implements the PagableSincerityResults interface.
 */
export function instanceOfPagableSincerityResults(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "demoItems" in value;
    isInstance = isInstance && "numUploadsThisMonth" in value;
    isInstance = isInstance && "cursor" in value;

    return isInstance;
}

export function PagableSincerityResultsFromJSON(json: any): PagableSincerityResults {
    return PagableSincerityResultsFromJSONTyped(json, false);
}

export function PagableSincerityResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagableSincerityResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(SincerityResultFromJSON)),
        'demoItems': ((json['demoItems'] as Array<any>).map(SincerityResultFromJSON)),
        'numUploadsThisMonth': json['numUploadsThisMonth'],
        'cursor': json['cursor'],
    };
}

export function PagableSincerityResultsToJSON(value?: PagableSincerityResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(SincerityResultToJSON)),
        'demoItems': ((value.demoItems as Array<any>).map(SincerityResultToJSON)),
        'numUploadsThisMonth': value.numUploadsThisMonth,
        'cursor': value.cursor,
    };
}

