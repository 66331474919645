import { configureStore } from "@reduxjs/toolkit";
import { storageSlice } from "./storageSlice";
import {
  authentication,
  authSlice,
  setAccessToken,
  setUser,
} from "./authSlice";
import { listenerMiddleware, startListening } from "./listener-middleware";
import { chatSlice } from "./chatSlice";
import { sinceritySlice } from "./sinceritySlice";
import { Configuration, DefaultConfig } from "../net";
import { BaseAPI } from "../environment";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    storage: storageSlice.reducer,
    chat: chatSlice.reducer,
    sincerity: sinceritySlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["storage/setUploadingFiles"],
      },
    }).prepend(listenerMiddleware.middleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

startListening({
  actionCreator: authSlice.actions.setAccessToken,
  effect: async (action, listenerApi) => {
    const accessToken =
      action.payload.accessToken === null
        ? undefined
        : action.payload.accessToken;
    DefaultConfig.config = new Configuration({
      basePath: BaseAPI,
      accessToken,
    });
  },
});

authentication.accessToken$.subscribe((value) => {
  store.dispatch(setAccessToken({ accessToken: value }));
});
authentication.user$.subscribe((value) => {
  const user = value === null ? undefined : value;
  store.dispatch(setUser({ user }));
});
