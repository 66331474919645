/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageStatus } from './MessageStatus';
import {
    MessageStatusFromJSON,
    MessageStatusFromJSONTyped,
    MessageStatusToJSON,
} from './MessageStatus';

/**
 * 
 * @export
 * @interface MessageOut
 */
export interface MessageOut {
    /**
     * 
     * @type {string}
     * @memberof MessageOut
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MessageOut
     */
    text: string;
    /**
     * 
     * @type {MessageStatus}
     * @memberof MessageOut
     */
    status: MessageStatus;
    /**
     * 
     * @type {string}
     * @memberof MessageOut
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageOut
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MessageOut
     */
    completedAt: Date;
}

/**
 * Check if a given object implements the MessageOut interface.
 */
export function instanceOfMessageOut(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "completedAt" in value;

    return isInstance;
}

export function MessageOutFromJSON(json: any): MessageOut {
    return MessageOutFromJSONTyped(json, false);
}

export function MessageOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageOut {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': json['text'],
        'status': MessageStatusFromJSON(json['status']),
        'userId': json['userId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'completedAt': (new Date(json['completedAt'])),
    };
}

export function MessageOutToJSON(value?: MessageOut | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'status': MessageStatusToJSON(value.status),
        'userId': value.userId,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'completedAt': (value.completedAt.toISOString()),
    };
}

