import { MessageOut, PagableMessages, QaApi } from "../net";
import { RootState } from "./store";
import { sliceWithThunks } from "./utils";

interface ChatState {
  isLoading: boolean;
  messages: MessageOut[];
}

const initialState: ChatState = {
  isLoading: false,
  messages: [],
};

export const chatSlice = sliceWithThunks({
  name: "chat",
  initialState,
  reducers: (create) => ({
    getMessages: create.asyncThunk(
      async (payload, thunkApi) => {
        const state = (thunkApi.getState() as RootState).chat;
        try {
          return await new QaApi().getMessages({ size: 10 });
        } catch (e) {
          return thunkApi.rejectWithValue("Failed to fetch messages");
        }
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        settled: (state) => {
          state.isLoading = false;
        },
        fulfilled: (state, action) => {
          state.messages = (action.payload as unknown as PagableMessages).items;
        },
      },
    ),
    sendMessage: create.asyncThunk<any, { text: string }>(
      async (payload, thunkApi) => {
        try {
          return await new QaApi().sendMessage({
            messageIn: { text: payload.text, context: {} },
          });
        } catch (e) {
          return thunkApi.rejectWithValue("Failed to send message");
        }
      },
      {
        pending: (state) => {
          state.isLoading = true;
        },
        settled: (state) => {
          state.isLoading = false;
        },
        fulfilled: (state, action) => {
          state.messages = [action.payload as MessageOut, ...state.messages];
        },
      },
    ),
  }),
  selectors: {
    selectLoading: (sliceState) => sliceState.isLoading,
    selectMessages: (sliceState) => sliceState.messages,
  },
});

export const { selectLoading, selectMessages } = chatSlice.getSelectors(
  (rootState: RootState) => rootState.chat,
);

export const { sendMessage, getMessages } = chatSlice.actions;
