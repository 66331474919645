import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./error-page";
import { Version } from "./environment";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import Loading from "./loading";
import { router } from "./routes/router";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
document.title = `Sincerity by Voicera [${Version}]`;

root.render(
  // <React.StrictMode>
  <div className="bg-background dark:bg-background-dark text dark:text-dark w-screen h-screen">
    <Provider store={store}>
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onReset={() => {}}
        resetKeys={["someKey"]}
      >
        <RouterProvider fallbackElement={<Loading />} router={router} />
      </ErrorBoundary>
    </Provider>
  </div>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
