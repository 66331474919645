/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageIn
 */
export interface MessageIn {
    /**
     * 
     * @type {string}
     * @memberof MessageIn
     */
    text: string;
    /**
     * 
     * @type {object}
     * @memberof MessageIn
     */
    context: object;
}

/**
 * Check if a given object implements the MessageIn interface.
 */
export function instanceOfMessageIn(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "context" in value;

    return isInstance;
}

export function MessageInFromJSON(json: any): MessageIn {
    return MessageInFromJSONTyped(json, false);
}

export function MessageInFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'context': json['context'],
    };
}

export function MessageInToJSON(value?: MessageIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'context': value.context,
    };
}

