/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageOut } from './MessageOut';
import {
    MessageOutFromJSON,
    MessageOutFromJSONTyped,
    MessageOutToJSON,
} from './MessageOut';

/**
 * 
 * @export
 * @interface PagableMessages
 */
export interface PagableMessages {
    /**
     * 
     * @type {Array<MessageOut>}
     * @memberof PagableMessages
     */
    items: Array<MessageOut>;
    /**
     * 
     * @type {string}
     * @memberof PagableMessages
     */
    cursor: string | null;
}

/**
 * Check if a given object implements the PagableMessages interface.
 */
export function instanceOfPagableMessages(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "cursor" in value;

    return isInstance;
}

export function PagableMessagesFromJSON(json: any): PagableMessages {
    return PagableMessagesFromJSONTyped(json, false);
}

export function PagableMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagableMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(MessageOutFromJSON)),
        'cursor': json['cursor'],
    };
}

export function PagableMessagesToJSON(value?: PagableMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(MessageOutToJSON)),
        'cursor': value.cursor,
    };
}

