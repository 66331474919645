/* tslint:disable */
/* eslint-disable */
/**
 * sincerity-web-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MessageStatus = {
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type MessageStatus = typeof MessageStatus[keyof typeof MessageStatus];


export function MessageStatusFromJSON(json: any): MessageStatus {
    return MessageStatusFromJSONTyped(json, false);
}

export function MessageStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageStatus {
    return json as MessageStatus;
}

export function MessageStatusToJSON(value?: MessageStatus | null): any {
    return value as any;
}

